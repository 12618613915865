<template>
  <div class="link-to-agr first-col list-item ml-0 column box is-align-self-flex-start has-background-white">
    <div
      class="info-line"
      :class="main_color"
    />

    <div class="is-align-self-flex-start box-set">
      <p class="has-text-grey has-text-weight-normal mb-3">{{ agent.factory.branch.name }} >
        {{ agent.factory.name }} </p>
      <div>
        <div class="is-flex is-justify-content-space-between">
          <router-link
            class="has-text-black font-size-agr"
            :to="{ path: '/device/views/' + agent.id }"
          >
            <b>{{ agent.device_name }}</b>
          </router-link>
          <p @click="is_opened =! is_opened">
            <font-awesome-icon
              class="drop"
              :icon="['fas', is_opened ? 'fa-chevron-up' : 'fa-chevron-down']"
            />
          </p>
        </div>
        <div class="columns mt-2">
          <div class="column tag-parent-mobile is-flex is-flex-wrap-wrap">
            <span
              class="tag-mobile has-text-weight-medium tag is-size-6 px-4 py-4 mr-3 mb-2"
              v-for="(card, idx) in cards"
              :key="`${agent.device_name}-${idx}`"
              :class="card.color"
            >{{ card.name }}</span>
          </div>
        </div>
      </div>
      <template v-if="is_opened">
        <div v-if="device_is_loading" style="width: 100%; height: 120px">
          <b-loading :is-full-page="false" :active="true"/>
        </div>
        <div v-else class="mt-3 is-flex-direction-column">
          <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap-reverse">
            <div class="is-flex is-flex-direction-column is-align-items-center mb-2">
              <div>
                <router-link
                  class="has-text-black"
                  :to="{ path: '/device/views/' + agent.id }"
                >Скорость,
                  <span
                    class="has-text-grey"
                  >об/мин</span>
                </router-link>
              </div>
              <div class="is-size-3 has-text-weight-bold has-text-success">
                {{ device['current_values'].speed || 0 }}
              </div>
            </div>
            <div>
              <b-image
                v-if="device['image_path']"
                :src="getImagePath()"
                custom-class="icon-agent"
              />
              <img
                v-else
                class="icon-agent"
                width="200px"
                src="@/assets/14.jpg"
                alt="device-icon"
              >

            </div>
          </div>
          <div class="is-flex is-flex-direction-column mb-3">
            <div class="has-text-black">Виброскорость,
              <span class="has-text-grey">мм/с</span>
            </div>
            <div
              class="vibs--grid"
              v-if="device['current_values'].vibs.length != 0"
            >
              <div
                class="is-flex is-flex-direction-column has-text-centered"
                v-for="(item,idx) in device['current_values'].vibs"
                :key="idx"
              >
                <router-link
                  class="is-size-4-mobile is-size-3-tablet has-text-weight-bold"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                  :class="item.color"
                >{{ item.value }}
                </router-link>
                <router-link
                  class="is-underlined has-text-grey has-text-weight-light"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                >{{ item.name }}
                </router-link>
                <span>{{ showHealth(item.health) }}</span>
              </div>
              <div
                v-for="(item,idx) in device.temperatures"
                :key="`temp-${idx}`"
                class="is-flex is-flex-direction-column has-text-centered"
              >
                <router-link
                  v-if="item.value"
                  class="is-size-4-mobile is-size-3-tablet has-text-weight-bold"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                  :class="item.color"
                >
                  {{ item.value }}
                </router-link>
                <router-link
                  class="is-underlined has-text-grey has-text-weight-light"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                >
                  {{ item.name }}
                </router-link>
              </div>
            </div>

            <div
              v-else
              class="vibs--grid"
              :style="{ gridTemplateRows: `repeat(${device.module_count/4}, 1fr)` }"
            >
              <div
                class="is-flex is-flex-direction-column has-text-centered"
                v-for="(item,idx) in device.module_count"
                :key="idx"
              >
                <div
                  class="is-size-4-mobile is-size-3-tablet has-text-weight-bold has-text-grey"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                  :class="item.color"
                >0
                </div>
                <router-link
                  class="is-underlined has-text-grey has-text-weight-light"
                  :to="{ name: 'DeviceTrendsVel', params: { device_id: agent.id, deviceName: agent.device_name } }"
                >VIB{{ item }}
                </router-link>
              </div>
            </div>

            <!--            <div-->
            <!--              class="temperatures&#45;&#45;grid"-->
            <!--              v-if="MessagesData['temperature_count'] > 0"-->
            <!--            >-->
            <!--              <div-->
            <!--                v-for="(item,idx) in MessagesData.temperatures"-->
            <!--                :key="idx"-->
            <!--                class="is-flex is-flex-direction-column has-text-centered"-->
            <!--              >-->
            <!--                <router-link-->
            <!--                  v-if="item.value"-->
            <!--                  class="is-size-4-mobile is-size-3-tablet has-text-weight-bold"-->
            <!--                  :to="{ name: 'DeviceTrends', params: { device_id: Agent.id, deviceName: Agent.device_name } }"-->
            <!--                  :class="item.color"-->
            <!--                >-->
            <!--                  {{ item.value }}-->
            <!--                </router-link>-->
            <!--                <router-link-->
            <!--                  class="is-underlined has-text-grey has-text-weight-light"-->
            <!--                  :to="{ name: 'DeviceTrends', params: { device_id: Agent.id, deviceName: Agent.device_name } }"-->
            <!--                >-->
            <!--                  {{ item.name }}-->
            <!--                </router-link>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div-->
            <!--              v-else-if="MessagesData.temperature_count > 0"-->
            <!--              class="temperatures&#45;&#45;grid"-->
            <!--            >-->
            <!--              <div-->
            <!--                v-for="(item,idx) in MessagesData.temperature_count"-->
            <!--                :key="idx"-->
            <!--                class="is-flex is-flex-direction-column has-text-centered"-->
            <!--              >-->
            <!--                <router-link-->
            <!--                  class="is-size-4-mobile is-size-3-tablet has-text-weight-bold"-->
            <!--                  :to="{ name: 'DeviceTrends', params: { device_id: Agent.id, deviceName: Agent.device_name } }"-->
            <!--                >-->
            <!--                  0-->
            <!--                </router-link>-->
            <!--                <router-link-->
            <!--                  class="is-underlined has-text-grey has-text-weight-light"-->
            <!--                  :to="{ name: 'DeviceTrends', params: { device_id: Agent.id, deviceName: Agent.device_name } }"-->
            <!--                >-->
            <!--                  T{{ item }}-->
            <!--                </router-link>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <template v-if="messagesCount > 0">
            <p class="mb-3 has-text-grey">Кол-во сообщений: {{ messagesCount }}</p>
            <div class="is-flex is-align-items-center">
              <div class="pr-2 is-clickable" @click="currentSlide--">
                <font-awesome-icon
                  :icon="['fas', 'fa-arrow-left']"
                ></font-awesome-icon>
              </div>
              <b-carousel
                v-model="currentSlide"
                :indicator="false"
                :arrow="false"
                icon-pack="fas"
                icon-size="is-medium"
                :autoplay="false"
                :repeat="false"
                :arrow-hover="false"
              >
                <b-carousel-item v-for="(message, idx) in messages" :key="message.id">
                  <section :class="[message.color]" draggable="true">
                    <div class="is-flex px-3 py-3" draggable="true">
                      <p draggable="true">
                        <font-awesome-icon
                          draggable="true"
                          class="px-1 mr-2"
                          :icon="['fas', 'fa-triangle-exclamation']"
                        ></font-awesome-icon>
                      </p>
                      <div class="is-flex is-flex-direction-column" draggable="true">
                        <p draggable="true">{{ message.msg }}</p>
                        <p class="has-text-grey has-text-weight-light" draggable="true">{{ message.date }}</p>
                      </div>
                    </div>
                  </section>
                </b-carousel-item>
              </b-carousel>
              <div class="ml-2 is-clickable" @click="currentSlide++">
                <font-awesome-icon
                  :icon="['fas', 'fa-arrow-right']"
                ></font-awesome-icon>
              </div>
            </div>
          </template>
          <div v-if="device.healths && device.healths.length > 0" class="mb-4">
            <p
              v-for="msg in device.healths"
              :key="`health-${msg.id}`"
              class="mb-1"
            >
              {{ msg.msg }}
            </p>
          </div>
          <!-- <div class="is-flex mt-3">
            <div class="is-flex has-text-success mr-3 is-align-items-center">
              <font-awesome-icon class="px-1 py-1" :icon="['fas', 'fa-bolt']"></font-awesome-icon>
              <p class="mx-1 my-1">1402 кВт/ч</p>
            </div>
            <div class="is-flex has-text-danger mr-3 is-align-items-center">
              <font-awesome-icon class="px-1 py-1" :icon="['fas', 'fa-temperature-quarter']"></font-awesome-icon>
              <p class="mx-1 my-1">98 °С</p>
            </div>
            <div class="is-flex has-text-warning is-align-items-center">
              <font-awesome-icon class="px-1 py-1" :icon="['fas', 'fa-droplet']"></font-awesome-icon>
              <p class="mx-1 my-1">0,1</p>
            </div>
          </div> -->
        </div>
      </template>
      <div class="has-text-grey mt-3">обновлен {{ last_active_at }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["agent"],
  data() {
    return {
      is_opened: false,
      device: null,
      device_is_loading: false,
      interval: undefined,
      cards: [],
      main_color: 'has-background-light',
      last_active_at: '...',
      messages: [],
      messagesCount: 0,
      currentSlide: 0,
      isFirstOpen: true
    }
  },
  watch: {
    is_opened(newValue) {
      if(newValue) {
        (async() => {
          await this.fetchDeviceInfo()
          this.isFirstOpen = false
          await this.getMessages()
          this.interval = setInterval(() => {
            this.fetchDeviceInfo()
            // this.getMessages()
          }, 60 * 1000)
        })()
      } else if(!newValue) {
        this.isFirstOpen = true
        clearInterval(this.interval)
      }
    }
  },
  mounted() {
    this.fetchDeviceInfo()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    showHealth(health) {
      return !isNaN(health) ? `${Number(health).toFixed(1)}%` : '';
    },
    async fetchDeviceInfo() {
      this.device_is_loading = this.isFirstOpen
      try {
        const { data } = await this.$axios.get(`user/agents/${this.agent.id}/last-message`)
        this.device = data?.data || []
        this.cards = data?.data?.cards || []
        this.main_color = data?.data?.main_color || '#c7c7c7'
        this.last_active_at = data?.data?.last_active_at || '...'
      } catch (e) {
        throw new Error(e)
      } finally {
        this.device_is_loading = false
      }
    },
    async getMessages(page) {
      this.currentPage = page || 1;
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`user/agents/${this.agent.id}/unread-messages/0/100`);
        this.messages = data?.data || [];
        this.messagesCount = data?.count || 0;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getImagePath() {
      return `${process.env.VUE_APP_API_URL}${this.device["image_path"]}`
    },
    async getCardInfo() {
      try {
        const { data } = await this.$axios.get(`user/agents/${this.agent.id}/cards`)
        this.last_active_at = data?.data?.last_active_at || '...'
        this.main_color = data?.data?.main_color || 'has-background-light'
        this.cards = data?.data?.cards || []
      } catch (e) {
        throw new Error(e)
      }
    }
  },

}
</script>

<style lang="scss">
.drop {
  font-size: 1.5037593984962405rem
}

.font-size-agr {
  font-size: 1.375rem;
}

.link-to-agr {
  font-size: 0.875rem;
}

.list-item {
  border-radius: 5px;
  box-shadow: 2px 2px 2px #c7c7c7;
  position: relative;
}

.template-item {
  width: 300px;
}

.icon-agent {
  height: min(100%, 300px) !important;
  width: auto !important;
  aspect-ratio: 1/1;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

@media (max-width: 1024px) {
  .tag-mobile {
    margin-bottom: 10px;
  }

  .box-set {
    padding: 25px 25px;
  }

  .tag-parent-mobile {
    display: flex;
    flex-direction: column;
  }
}

</style>
