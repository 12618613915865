<template>
  <div class="columns is-desktop mx-3">
    <div class="column is-one-quarter">
      <div class="has-background-white p-4">
        <div class="mobile-view-nav">
          <div class="is-flex is-justify-content-space-between is-align-items-center" @click="OpenNav =! OpenNav">
            <p class="menu__title has-text-weight-bold is-size-6">Все агрегаты</p>
            <p>
              <font-awesome-icon class="drop" :icon="['fas', !OpenNav ? 'fa-chevron-down' : 'fa-chevron-up']" />
            </p>
          </div>
          <template v-if="OpenNav">
            <div v-for="item in menu" :key="item.id" class="mb-2">
              <div>
                <span class="menu__item__text has-text-info">{{ item.name }} </span>
                <div
                  v-for="factory in item['factories']"
                  :key="factory.id"
                  class="ml-3 mt-1"
                >
                  <p class="menu__item__sub-text mb-1">{{ factory.name }}</p>
                  <div
                    v-for="agent in factory['agents']"
                    :key="agent.id"
                    class="ml-3"
                  >
                    <router-link
                      :to="{ path: '/device/views/' + agent.id }"
                      class="has-text-info menu__item-text ml-4"
                    >
                      <b>{{ agent.device_name }}</b>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="desktop-view-nav">
          <div class="is-flex is-justify-content-space-between">
            <p class="menu__title has-text-weight-bold mb-2 is-size-6">Все агрегаты</p>
          </div>
          <div v-for="item in menu" :key="item.id" class="mb-2">
            <div>
              <span class="menu__item__text has-text-info">{{ item.name }} </span>
              <div
                v-for="factory in item['factories']"
                :key="factory.id"
                class="ml-3 mt-1"
              >
                <p class="menu__item__sub-text mb-1">{{ factory.name }}</p>
                <div
                  v-for="agent in factory['agents']"
                  :key="agent.id"
                  class="ml-3"
                >
                  <router-link
                    :to="{ path: '/device/views/' + agent.id }"
                    class="has-text-info menu__item-text ml-4"
                  >
                    <b>{{ agent.device_name }}</b>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column px-0">
      <div class="columns is-variable is-0">
        <!-- если будет нужно сделать так как в макете, вставить текст ниже в блок выше -->
        <!-- is-flex-direction-row-reverse -->

        <div class="column first-col py-0">
          <div class="column" v-for="(item, idx) in FirstColumn" :key="idx">
            <Agents :agent="item" />
          </div>
        </div>

        <div class="column second-col py-0">
          <div class="column" v-for="(item, idx) in SecondColumn" :key="item.idx">
            <Agents :agent="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import Agents from "@/components/ViewsAgent/Agents.vue";

export default {
  components: {
    Agents,
  },
  data() {
    return {
      Data: [],
      OpenPage: false,
      OpenNav: false,
      FirstColumn: [],
      SecondColumn: [],
      menu: []
    };
  },
  created() {
    this.ChangeNavName("Мониторинг");
  },
  mounted() {
    this.fetchMenu()
  },
  methods: {
    ...mapActions(["FetchData"]),
    ...mapMutations(["ChangeNavName"]),
    async fetchMenu() {
      try {
        const { data } = await this.$axios.get('user/agents')
        if(data.data?.length > 0) {
          data.data.forEach((data, idx) => {
            if(idx%2 === 0) this.FirstColumn.push(data)
            else this.SecondColumn.push(data)
          })
        }
        this.menu = data?.['menu_f'] || []
      } catch (e) {
        throw new Error(e)
      }
    }
  },
};
</script>

<style scoped>
.menu__item__text {
  font-size: 18px;
  line-height: 20px;
}

.menu__item__sub-text {
  color: #0091ffDD;
  font-size: 16px;
  line-height: 18px;
}

.drop {
  font-size: 25px
}

.menu__title {
  color: black !important;
  font-size: 14px;
}

.list-item {
  margin-left: 10px;
}

.link-to-agr {
  font-size: 0.875rem;
}

@media (max-width: 1024px) {
  .device-mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .column {
    width: 100%;
  }

  .list-item {
    margin-left: 0;
  }

  .main-mobile-view {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  }


}
</style>
